export function login(
  phoneNumber,
  countryCode,
  encData,
  history,
  resend,
  queryParams
) {
  return {
    type: 'LOGIN',
    payload: {
      phoneNumber,
      countryCode,
      encData,
      history,
      resend,
      queryParams,
    },
  };
}

export function logout(history) {
  return {
    type: 'LOGOUT',
    payload: {
      history,
    },
  };
}

export function loginMagicLink(magicLink, history, tab) {
  return {
    type: 'SSO_MAGIC_CODE',
    payload: {
      magicLink,
      history,
      tab,
    },
  };
}

export function loginPhonepe(grantToken, history, queryParams) {
  return {
    type: 'SSO_PHONEPE',
    payload: {
      grantToken,
      history,
      queryParams,
    },
  };
}

export function loginSso(encrypted, clientId, history) {
  return {
    type: 'SSO_LOGIN',
    payload: {
      encrypted,
      clientId,
      history,
    },
  };
}

export function loginWithToken(authorization, queryParams, history) {
  return {
    type: 'LOGIN_WITH_TOKEN',
    payload: {
      authorization,
      queryParams,
      history,
    },
  };
}

export function verifyOtp(phoneNumber, otp, history, queryParams) {
  return {
    type: 'VERIFY_OTP',
    payload: {
      phoneNumber,
      otp,
      history,
      queryParams,
    },
  };
}

export function submitUserDetails(name, dob, gender, history) {
  return {
    type: 'SUBMIT_USER_INFO',
    payload: {
      name,
      dob,
      gender,
      history,
    },
  };
}

export function updateLoginState(state) {
  return {
    type: 'UPDATE_LOGIN_STATE',
    payload: {
      state,
    },
  };
}

export function setFitcoinsBalance(balance) {
  return {
    type: 'SET_FITCOIN_BALANCE',
    payload: {
      balance,
    },
  };
}

export function addChatMessage(chatMessage) {
  return {
    type: 'ADD_CHAT_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function setChatInfo(chatInfo) {
  return {
    type: 'SET_CHAT_INFO',
    payload: {
      ...chatInfo,
    },
  };
}

export function setChatMessages(chatMessages) {
  console.log(chatMessages);
  return {
    type: 'SET_CHAT_MESSAGES',
    payload: {
      chatMessages,
    },
  };
}

export function resetChat() {
  return {
    type: 'RESET_CHAT',
  };
}

export function getConsultationSummary() {
  return {
    type: 'GET_CONSULTATION_SUMMARY',
  };
}

export function cancelConsultationBooking(requestId, history) {
  return {
    type: 'CANCEL_CONSULTATION_BOOKING',
    payload: {
      requestId,
      history,
    },
  };
}

export function rescheduleConsultationBooking(requestId, doctorName, history) {
  return {
    type: 'RESCHEDULE_CONSULTATION_BOOKING',
    payload: {
      requestId,
      doctorName,
      history,
    },
  };
}

export function showError(errorTitle, errorDesc) {
  return {
    type: 'SHOW_ERROR',
    payload: {
      hasError: true,
      errorTitle,
      errorDesc,
    },
  };
}

export function hideError() {
  return {
    type: 'HIDE_ERROR',
    payload: null,
  };
}

export function setDoctorTyping(typing, doctorId) {
  return {
    type: 'SET_DOCTOR_TYPING',
    payload: {
      typing,
      doctorId,
    },
  };
}

export function setSearchText(text) {
  return {
    type: 'SET_SEARCH_TEXT',
    payload: text,
  };
}

export function setAppConfig(config) {
  return {
    type: 'SET_APP_CONFIG',
    payload: {
      config,
    },
  };
}

export function addBotChatMessage(chatMessage) {
  return {
    type: 'ADD_BOT_CHAT_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function setBotChatInfo(chatInfo) {
  return {
    type: 'SET_BOT_CHAT_INFO',
    payload: {
      ...chatInfo,
    },
  };
}

export function setBotChatMessages(chatMessages) {
  console.log(chatMessages);
  return {
    type: 'SET_BOT_CHAT_MESSAGES',
    payload: {
      chatMessages,
    },
  };
}

export function resetBotChat() {
  return {
    type: 'RESET_BOT_CHAT',
  };
}

export function startVideoCall(payload) {
  return {
    type: 'START_VIDEO_CALL',
    payload,
  };
}

export function stopVideoCall() {
  return {
    type: 'STOP_VIDEO_CALL',
  };
}

export function setVideoFeedbackInfo(info) {
  return {
    type: 'SET_VIDEO_FEEDBACK_INFO',
    payload: info,
  };
}

export function setService(value) {
  return {
    type: 'SET_SERVICE',
    payload: value,
  };
}

export function setMemberId(memberId) {
  return {
    type: 'SET_MEMBER_ID',
    payload: memberId,
  };
}
export function setModuleName(name) {
  return {
    type: 'SET_MODULE_NAME',
    payload: name,
  };
}

export function updateHraScore(data) {
  return {
    type: 'UPDATE_HRA_SCORE',
    payload: data,
  };
}

export function updateHraToTataAig(value) {
  return {
    type: 'UPDATE_HRA_TO_TATA_AIG',
    payload: value,
  };
}

export function updateGoogleFitnessState(data) {
  return {
    type: 'UPDATE_GOOGLE_FITNESS_STATE',
    payload: data,
  };
}

export function updateGoogleFitnessToTataAig(value) {
  return {
    type: 'UPDATE_FITNESS_TO_TATA_AIG',
    payload: value,
  };
}

export function updateHraTaken(body) {
  return {
    type: 'UPDATE_HRA_TAKEN',
    payload: body,
  };
}

export function updatePdfLink(link) {
  console.log('Link', link);
  return {
    type: 'UPDATE_PDF_LINK',
    payload: link,
  };
}

export function updateFitnessActiveTab(payload) {
  return {
    type: 'SET_FITNESS_ACTIVE_TAB',
    payload,
  };
}

export function setCurrentActiveTab(value) {
  return {
    type: 'SET_CURRENT_ACTIVE_TAB',
    payload: value,
  };
}

export function setGoogleFitPermission(info) {
  return {
    type: 'SET_GOOGLE_FIT_PERMISSION',
    payload: info,
  };
}

export function setIOSUser(value) {
  return {
    type: 'SET_IOS_USER',
    payload: value,
  };
}

export function setFitBitConnected(value) {
  return {
    type: 'SET_FITBIT_USER',
    payload: value,
  };
}

export function preSelectedVertical(verticalId) {
  return {
    type: 'PRE_SELECTED_VERTICAL',
    payload: verticalId,
  };
}
export function clearAxiosCache() {
  return {
    type: 'REMOVE_AXIOS_CACHE',
  };
}

export function showOnlyGP(value) {
  return {
    type: 'SHOW_ONLY_GP',
    payload: value,
  };
}

export function filterOutGP(value) {
  return {
    type: 'FILTER_OUT_GP',
    payload: value,
  };
}
