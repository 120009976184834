import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/common/Header';
import NutritionistCard from '../../images/weight-management/nutritionist-card.png';
import FitnessCard from '../../images/weight-management/fitness-workout.png';
import styled from 'styled-components';
import HealthDataIcon from '../../images/home/health-data-icon.svg';
import HealthDataHero from '../../images/home/health-data-hero.svg';
import { useSelector } from 'react-redux';
import WaterLogCard from '../../components/home/WaterLogCard';
import { useRef } from 'react';
import { useEffect } from 'react';
import config from '../../config';
import axios from 'axios';
import { useState } from 'react';
import { showError } from '../../utils';
import { setGoogleFitPermission } from '../../actions';
import { getNutritionDetails } from '../../services';
import GoalProgressCard from '../../components/home/GoalProgressCard';
import ActivityTracker from '../../components/home/ActivityTracker';
import { useDispatch } from 'react-redux';

const Card = styled.button`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  text-align: left;
  width: 100%;
`;

const Container = styled.div`
  height: 92vh;
`;

const WeightManagement = () => {
  const history = useHistory();
  const { googleFitnessPermission, sdkPlatform, currentSteps, currentSleep } =
    useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const showFitnessConnector = useSelector(
    (state) => state.app.showFitnessConnector
  );
  const { authToken, userId, fitbitUser } = user;

  const activityTracker = useRef();
  const [steps, setSteps] = useState(0);
  const [meals, setMeals] = useState(0);
  const [sleep, setSleep] = useState(0);
  const [currentCal, setCurrentCal] = useState(0);
  const [totalCals, setTotalCals] = useState(0);

  const dispatch = useDispatch();

  const getFitneesDetailsOfUser = async () => {
    const headers = {
      authorization: authToken,
    };

    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/console/home?v=180000717`,
        {
          headers,
        }
      );
      const resp = response.data;
      if (resp.message === 'success') {
        console.log(resp.home[0].cards[0].elements);
        setSteps(resp.home[0].cards[0].elements[0]);
        setMeals(resp.home[0].cards[0].elements[1]);
        setSleep(resp.home[0].cards[0].elements[2]);
      } else {
        const err = new Error('Invalid response');
        err.data = response;
        showError(err);
        throw err;
      }
    } catch (e) {
      showError(e);
      throw e;
    }
  };

  function navigateBack() {
    if (window?.Android?.closeView) {
      window.Android.closeView(true);
    } else if (window?.webkit?.messageHandlers?.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'closeView',
          tataUser: true,
        })
      );
    } else {
      history.goBack();
    }
  }

  useEffect(() => {
    if (window.ReactNativeWebView) {
      console.log('Connecting to Android SDK Fitness APP');
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'UPDATE_PLATFORM',
        })
      );
    }

    window.updateFitnessPermissions = (fitnessPermission, step, sleep) => {
      const data = {
        permissionForGoogleFit: fitnessPermission,
        currentSteps: step,
        currentSleep: sleep,
      };
      dispatch(setGoogleFitPermission(data));
    };

    window.googleFitStatus = (isConnected) => {
      console.log({ googleFitnessPermission }, 'called after disconnecting');
      const data = {
        permissionForGoogleFit: isConnected,
        currentSteps: 0,
        currentSleep: 0,
      };
      dispatch(setGoogleFitPermission(data));
    };

    //  window.disconnectFromGoogleFit = () => {
    //   const data = {
    //     permissionForGoogleFit: false,
    //     currentSteps: 0,
    //     currentSleep: 0,
    //   };
    //   dispatch(setGoogleFitPermission(data));
    //  };

    // GA Tracking

    // this.fetchConsultationBlocker();
    // if (location.search) {
    //   const params = new URLSearchParams(location.search);
    //   const permissionForGoogleFit = params.get('fitnessPermission') === 'true';
    //   const NoOfSteps = params.get('steps');
    //   const NoOfSleep = params.get('sleep');
    //   const data = {
    //     permissionForGoogleFit,
    //     currentSteps: NoOfSteps,
    //     currentSleep: NoOfSleep,
    //   };
    //   dispatch(setGoogleFitPermission(data));
    // }
    if (googleFitnessPermission) {
      // if (location.search) {
      // const params = new URLSearchParams(location.search);
      // const permissionForGoogleFit =
      //   params.get('fitnessPermission') === 'true';
      // if (!permissionForGoogleFit) {
      if (fitbitUser) {
        // Call API and fetch data
      } else {
        if (
          window.Android !== undefined &&
          window.Android.askForGoogleFitGraphData
        ) {
          window.Android.askForGoogleFitGraphData();
        } else if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.visitIosView
        ) {
          window.webkit.messageHandlers.visitIosView.postMessage(
            JSON.stringify({
              method: 'askForGoogleFitGraphData',
            })
          );
        } else if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              method: 'askForGoogleFitGraphData',
            })
          );
        }
      }
      // }
      // } else {
      //   if (window.Android !== undefined && window.Android.connectToGoogleFit) {
      //     window.Android.connectToGoogleFit();
      //   } else if (
      //     window.webkit &&
      //     window.webkit.messageHandlers &&
      //     window.webkit.messageHandlers.visitIosView
      //   ) {
      //     window.webkit.messageHandlers.visitIosView.postMessage(
      //       JSON.stringify({
      //         method: 'connectToGoogleFit',
      //       })
      //     );
      //   } else if (window.ReactNativeWebView) {
      //     window.ReactNativeWebView.postMessage(
      //       JSON.stringify({
      //         method: 'CONNECT_TO_GOOGLE_FIT',
      //       })
      //     );
      //   }
      // }
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const timeStamp = today.valueOf();
    getFitneesDetailsOfUser();
    getNutritionDetails(timeStamp, userId, authToken).then((res) => {
      setCurrentCal(res.totalToday);
      setTotalCals(res.remainingCals);
    });
  }, []);

  return (
    <div className="h-full w-full bg-textbox ">
      <Header title="Weight Management Program" onTap={navigateBack}></Header>
      <Container className="overflow-y-auto">
        {/* Show fit connect card only if HRA done */}
        {showFitnessConnector && (
          <>
            {window.Android ||
            (window.webkit && window.webkit.messageHandlers) ||
            window.wkWebView ||
            window.ReactNativeWebView ? (
              googleFitnessPermission || fitbitUser ? (
                <div className="p-10px border-b border-txtlight-shades4">
                  <GoalProgressCard
                    history={history}
                    steps={steps}
                    meals={meals}
                    sleep={sleep}
                    currentSteps={currentSteps || steps?.currentValue}
                    currentSleep={currentSleep || sleep?.currentValue}
                    currentCals={currentCal}
                    totalCals={totalCals}
                    authToken={authToken}
                  />
                </div>
              ) : (
                <div className="p-10px border-b border-txtlight-shades4">
                  <ActivityTracker
                    user={user}
                    iosUser={
                      sdkPlatform === 'IOS' ||
                      (window.webkit && window.webkit.messageHandlers) ||
                      window.wkWebView
                        ? true
                        : false
                    }
                    ref={activityTracker}
                  />
                </div>
              )
            ) : (
              <div></div>
            )}
          </>
        )}
        <Section
          heading="Get a personalised diet plan"
          onClick={() => {
            history.push('/consultation/doctor-select', {
              verticalId: 4,
              showVerticals: false,
              showSearch: false,
            });
          }}
        >
          <img src={NutritionistCard} alt="nutritionist-card" />
        </Section>
        <Section
          onClick={() => history.push('/health-content/fitness-home')}
          heading="Health Content"
        >
          <img src={FitnessCard} alt="fitness-card" />
        </Section>
        <Section heading="Log your health data">
          <div>
            <Card
              className="w-half mt-2"
              onClick={() => history.push('/health-content/health-data')}
            >
              <div
                className="flex justify-start p-15 items-center"
                style={{ paddingBottom: '0' }}
              >
                <img
                  src={HealthDataIcon}
                  alt="health-data-icon"
                  width={'20px'}
                  height={'20px'}
                />
                <h4 className="text-caption-l font-semibold ml-8px">
                  Health Data
                </h4>
              </div>
              <div
                className="mt-16px mb-8px flex justify-between"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
              >
                <div>
                  <p className="text-body-l text-txtlight font-semibold">
                    Monitor your health
                  </p>
                  <p className="text-body-l text-sub">
                    Check your logs over time
                  </p>
                </div>
                <img src={HealthDataHero} alt="doctor" />
              </div>
              <div className="cursor-pointer font-semibold text-body-l text-primary flex items-start justify-center py-16px border-t border-solid border-txtsecondary-shades7">
                Log Health Data
              </div>
            </Card>
            <div className="mt-20px mb-52px">
              <WaterLogCard authToken={authToken} history={history} />
            </div>
          </div>
        </Section>
      </Container>
    </div>
  );
};

const Section = ({ heading, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="p-20px px-24px border-b border-txtlight-shades4 flex flex-col justify-start w-full"
    >
      <p className="text-body-l font-bold">{heading}</p>
      <div className="mt-12px w-full">{children}</div>
    </button>
  );
};

export default WeightManagement;
