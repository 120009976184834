import config from '../config';

export const defaultState = {
  userId: null,
  authToken: null,
  userName: null,
  userEmail: null,
  countryCode: null,
  userPhone: null,
  dateOfBirth: null,
  userAge: 0,
  userGender: null,
  otpVerified: false,
  emailVerified: false,
  sponsorId: 0,
  verifiedSponsor: false,
  userUUID: null,
  platform: null,
  oldPhone: null,
  versionCode: null,
  referralCode: null,
  referralLink: null,
  createdAt: null,
  ftAvailable: false,
  sponsorCommonName: null,
  feedAvailable: false,
  qChat: false,
  inviteShareText: null,
  profileImage: null,
  inviteFitReward: null,
  supportPhone: null,
  streamId: 0,
  userChannelGroup: null,
  validateSponsor: false,
  walletBalance: 0,
  memberId: null,
  policyNumber: null,
  searchText: '',
  dependentsList: '',
  patientsList: '',
  secondaryPhoneNumber: '',
  updatedPdfLink: null,
  fitbitUser: false,
  ticketingUrl: config.ticketingBaseUrl,
  elderlyPlan: false,
  freeVerticals: false,
  preSelectedOnlineVertical: null,
  showOnlyGP: false,
  filterOutGP: false,
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      return Object.assign({}, state, action.payload.userInfo);
    case 'UNSET_USER_INFO':
      return Object.assign({}, defaultState);
    case 'AUTH_TOKEN':
      return Object.assign({}, state, {
        authToken: action.payload,
      });
    case 'SET_FITCOIN_BALANCE':
      return Object.assign({}, state, {
        walletBalance: action.payload.balance,
      });
    case 'SET_SEARCH_TEXT':
      return Object.assign({}, state, {
        searchText: action.payload,
      });
    case 'SET_MEMBER_AND_DEPENDENTS': {
      return Object.assign({}, state, {
        memberId: action.payload.memberId,
        policyNumber: action.payload.policyNumber,
        dependentsList: action.payload.dependentsList,
        patientsList: action.payload.patientsList,
        showMainUser: action.payload.showMainUser,
        secondaryPhoneNumber: action.payload.phoneNumber,
        elderlyPlan: action.payload.elderlyPlan,
      });
    }
    case 'SET_FREE_VERTICALS': {
      return Object.assign({}, state, {
        freeVerticals: action.payload,
      });
    }
    case 'UPDATE_PDF_LINK': {
      return Object.assign({}, state, {
        updatedPdfLink: action.payload,
      });
    }
    case 'SET_FITBIT_USER': {
      return Object.assign({}, state, {
        fitbitUser: action.payload,
      });
    }
    case 'PRE_SELECTED_VERTICAL': {
      return Object.assign({}, state, {
        preSelectedOnlineVertical: action.payload,
      });
    }
    case 'SHOW_ONLY_GP': {
      return Object.assign({}, state, {
        showOnlyGP: action.payload,
      });
    }
    case 'FILTER_OUT_GP': {
      return Object.assign({}, state, {
        filterOutGP: action.payload,
      });
    }
    default:
      return state;
  }
}
